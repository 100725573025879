import React from "react";
import { NavLink } from "react-router-dom";
import UserButton from "./UserButton";
import SidebarNav from "./SidebarNav";
import PagesNav from "./PagesNav";

const Header = () => {
  // const language = [
  //   {
  //     label: "ES",
  //     command: () => navigate("signIn"),
  //   },
  //   {
  //     label: "EN",
  //     command: () => navigate("signIn"),
  //   },
  //   {
  //     label: "PR",
  //     command: () => navigate("signIn"),
  //   },
  // ];
  return (
    <header className="flex surface-0 aling-items-center justify-content-between pt-3 pb-2 px-2 md:px-0 mb-6">
      <div className="flex">
        <NavLink
          to="/"
          className="flex text-primary no-underline"
          style={{ width: 90 }}
        >
          <i className="pi pi-bolt" style={{ fontSize: 40, marginRight: 2 }} />
          <div>Doing-Sports</div>
        </NavLink>
      </div>

      <nav className="hidden md:flex">
        <PagesNav />
      </nav>
      {/* <SplitButton
          icon="pi pi-globe"
          label="ES"
          model={language}
          outlined
          rounded
        /> */}

      <SidebarNav />
      <UserButton />
    </header>
  );
};

export default Header;
