import { ProgressSpinner } from "primereact/progressspinner";
import React from "react";

export const SpinnerPage = () => {
  return (
    <div className="flex flex-column h-screen justify-content-center">
      <ProgressSpinner />
    </div>
  );
};
