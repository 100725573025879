import { AuthUser } from "../../models/auth";
import { User } from "../../models/user";
import { api } from "./api";

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    signIn: build.mutation<User, AuthUser>({
      query: (data) => ({
        url: "auth/signIn",
        method: "POST",
        body: data,
      }),
    }),
    signInById: build.mutation<User, { id: string; email: string }>({
      query: ({ id, email }) => ({
        url: `auth/${id}/${email}`,
      }),
    }),
  }),
});

export const { useSignInByIdMutation, useSignInMutation } = authApi;
