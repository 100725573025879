import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AuthStateProps } from "../../models/auth";
import { User } from "../../models/user";

const initialState: AuthStateProps = {
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    saveRols: (state, actions: PayloadAction<string[]>) => {
      const { payload } = actions;
      if (state.user) state.user.rols = payload;
    },
    signIn: (state, actions: PayloadAction<User>) => {
      const { payload } = actions;
      state.user = payload;
    },
    signOut: (state) => {
      state.user = null;
    },
  },
});

export const { actions } = authSlice;
export default authSlice.reducer;
