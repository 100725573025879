export enum ToasterTypes {
  SUCCESS = "success",
  ERROR = "error",
  WARN = "warn",
  INFO = "info",
}

export enum SeveritiesTypes {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  DANGER = "danger",
  HELP = "help",
}

export enum Sexes {
  MALE = "M",
  FEMALE = "F",
}

export enum Themes {
  Dark = "dark",
  Light = "light",
}

export enum MonthlyFee {
  UNPAID = "Unpaid",
  DEFEATED = "Defeated",
  FOR_DEFEATED = "For_defeated",
  PAID = "Paid",
  OVERPAIMENT = "Overpayment",
  INCOMPLETE = "Incomplete",
  WITHOUT = "No_fee",
}

export enum PayMethods {
  TRANSFER = "Transferencia",
  CASH = "Efectivo",
}
