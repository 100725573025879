import { useTranslation } from "react-i18next";

export const useNavePagesItems = () => {
  const { t } = useTranslation();

  const navPages = [
    {
      url: "/",
      name: t("navbar.home"),
      icon: "pi pi-home",
    },
    {
      url: "/sports",
      name: t("navbar.sports"),
      icon: "pi pi-discord",
    },
    {
      url: "/institutions",
      name: t("navbar.institutions"),
      icon: "pi pi-building",
    },
    {
      url: "/plans",
      name: t("navbar.plans"),
      icon: "pi pi-star-fill",
    },
  ];

  return navPages;
};
