import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { fetchAuthSession } from "aws-amplify/auth";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API}${process.env.REACT_APP_API_PRIVATE}`,
  prepareHeaders: async (headers) => {
    const { tokens } = await fetchAuthSession();
    const idToken = tokens?.accessToken?.toString() || "";
    if (idToken) {
      headers.set("Authorization", `Bearer ${idToken}`);
    }
    return headers;
  },
});

export const api = createApi({
  reducerPath: "dsAPI",
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  baseQuery: baseQuery,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: ["Auth"],

  endpoints: () => ({}),
});
