/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect } from "react";
import Header from "./components/Header";
import { PrimeReactProvider } from "primereact/api";
import { Outlet, useLocation } from "react-router-dom";
import { ToastCustom } from "../../components/ToastCustom/ToastCustom";
import { I18n } from "aws-amplify/utils";
import { translations } from "@aws-amplify/ui-react";
import Footer from "./components/Footer";
import { fetchAuthSession, getCurrentUser, signOut } from "aws-amplify/auth";
import { useAppDispatch, useAppSelector } from "../../hooks/ReduxHooks";
import { useSignInByIdMutation } from "../../store/services/auth";
import { AuthStateProps } from "../../models/auth";
import { shallowEqual } from "react-redux";
import { actions } from "../../store/slices/AuthSlice";

I18n.putVocabularies(translations);
I18n.setLanguage("es");
export const PageLayout = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const authData = useAppSelector(
    (state: { auth: AuthStateProps }) => state.auth,
    shallowEqual
  );

  const [signInById] = useSignInByIdMutation();

  async function currentSession() {
    try {
      const { tokens, userSub } = await fetchAuthSession();
      const { signInDetails } = await getCurrentUser();
      const email = signInDetails?.loginId ?? "";
      const idToken = tokens?.accessToken?.toString() ?? "";
      const idUser = userSub?.toString() ?? "";
      return { idToken, idUser, email };
    } catch (err) {
      dispatch(actions.signOut());
      return { idToken: "", idUser: "", email: "" };
    }
  }

  useLayoutEffect(() => {
    async function loadSession() {
      const { idToken, idUser, email } = await currentSession();
      if (
        idUser &&
        idToken &&
        (!authData.user || authData.user.email !== email)
      ) {
        signInById({ id: idUser, email: email })
          .unwrap()
          .then((data) => {
            dispatch(actions.signIn(data));
          })
          .catch(() => {
            dispatch(actions.signOut());
            signOut();
          });
      }
    }
    loadSession();
  }, [pathname]);
  return (
    <PrimeReactProvider>
      <div className="flex flex-column justify-content-between min-h-screen">
        <section>
          <Header />
          <main>
            <Outlet />
          </main>
        </section>
        <Footer />
      </div>

      <ToastCustom />
    </PrimeReactProvider>
  );
};
