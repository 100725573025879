import { Rootstate } from "../store/store";
import { useAppSelector } from "./ReduxHooks";

interface verifyRolProps {
  SA?: boolean;
  A?: boolean;
  U?: boolean;
}

export const useVerifyRol = () => {
  const { user } = useAppSelector((state: Rootstate) => state.auth);
  const rols = user?.rols || [];
  const firstRol = process.env.REACT_APP_SA || "";
  const secondRol = process.env.REACT_APP_A || "";
  const thirdRol = process.env.REACT_APP_U || "";
  const verifyRol = ({ SA, A, U }: verifyRolProps) => {
    return (
      (!!SA && rols.includes(firstRol)) ||
      (!!A && rols.includes(secondRol)) ||
      (!!U && rols.includes(thirdRol))
    );
  };
  return { verifyRol };
};
